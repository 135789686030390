<template>
	<div class="page discuss_page">
		<div class="discuss_typebox">
			<div class="block30"></div>
			<div class="w1200">
				<div class="titlename fontsize24" @click="sysgoback()">
					<img class="icon" src="../assets/static/leftgo.png"/>
					<div class="text">话题详情</div>
				</div>
			</div>
		</div>
		<div class="discuss_detailbox">
			<div class="w1200 flexdisplay flexbetween">
				<div class="leftbox" v-if="formObj.title">
					<div class="topbox">
						<div class="titlebox fontsize24">{{formObj.title}}</div>
						<div class="descbox" v-if="formObj.hlUser">
							<div class="userinfo">
								<img :src="formObj.hlUser.hlImage || require('@/assets/logo.png')" class="logoimg">
								<div class="text clamp fontsize16">{{formObj.hlUser.nikename}}</div>
							</div>
							<div class="timer">{{formObj.createDate}}</div>
						</div>
						<div class="descbox" v-if="!formObj.hlUser">
							<div class="userinfo">
								<img :src="require('@/assets/logo.png')" class="logoimg">
								<div class="text clamp fontsize16">匿名</div>
							</div>
							<div class="timer">{{formObj.createDate}}</div>
						</div>
					</div>
					<div class="centerbox">
						<div class="w100" v-if="bannerList.length>0">
						    <el-carousel :interval="1800">
						        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
						     		<div class="box1">
						     			<div class="imgbox">
						     			   <img class="img100" :src="item"/>
						     			</div>
						     		</div>
						        </el-carousel-item>
						    </el-carousel>
						</div>
						<div class="contextbox fontsize16">
							<div class="text" v-text="formObj.contents"></div>
						</div>
					</div>
					<div class="bottombox">
						<div class="pinglunbox fontsize16">
							<div class="titletext"><span>评论（{{total}}）</span></div>
							<div class="inputbox">
								<textarea v-model="ctCommentInfo.comment" class="textarea" placeholder="发表评论"></textarea>
								<div class="btnbox">
									<div class="imgicon">
										<el-upload class="upload-demo" action="" :http-request="handlelistSuccess" :show-file-list="false"
											:before-upload="beforelistUpload" multiple>
											<img src="../assets/static/upload.png" class="img00">
										</el-upload>
									</div>
									<div class="btn1" @click="handlecomment">发布</div>
								</div>
								<div class="content_imgbox" v-show="imagesList.length>0">
									<div class="detaildiv">
										<draggable v-model="imagesList" class="image-list" @change="dragChange">
											<div v-for="(image, imgindex) in imagesList" :key="imgindex" class="image-wrap">
												<img :src="image" class="imgStyle" referrerPolicy="no-referrer">
												<div class="icon-wrap" style="cursor: pointer;">
													<div style="margin: 0 5px;"><i class="el-icon-delete" @click="removeimglist(imgindex)" /></div>
													<div style="margin: 0 5px;"><i class="el-icon-zoom-in" @click="checkimglist(imgindex,imagesList)" /></div>
													<!-- <div style="margin: 0 5px;"><i class="el-icon-upload" @click="sendimglist(imgindex)" /></div> -->
												</div>
											</div>
											<el-upload class="avatar-uploaderde" action="" :http-request="handlelistSuccess" :show-file-list="false"
												:before-upload="beforelistUpload" multiple>
												<i class="el-icon-plus avatar-uploader-iconde" />
											</el-upload>
										</draggable>
										<div slot="tip" class="el-upload__tip">可拖拽图片换顺序，只能上传jpg/png文件，建议大小不超过1m</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pinglun_box">
						<div class="itemobj" v-for="(item,index) in dataList" :key="index">
							<div class="topbox">
								<div class="descbox">
									<div class="userinfo">
										<img :src="item.hlUser.hlImage || require('@/assets/logo.png')" class="logoimg">
										<div class="text clamp fontsize16">{{item.hlUser.nikename}}</div>
									</div>
									<div class="timer">{{item.createDate}}</div>
								</div>
								<div class="editbox">
									<div class="zdimg" v-if="userInfo.uuid==formObj.userUuid" @click="handleTopconmem(item,index)">
										<img v-if="item.topFlag==0" src="../assets/static/zd.png" class="img100">
										<img v-if="item.topFlag==1" src="../assets/static/zdhover.png" class="img100">
									</div>
									<div class="scimg" v-if="userInfo.uuid==formObj.userUuid || userInfo.uuid==item.userUuid" @click="handledelconmem(item,index)">
										<img src="../assets/static/sc.png" class="img100">
									</div>
								</div>
							</div>
							<div class="conbox">
								<div class="context fontsize16">
									<div class="text" v-html="item.comment"></div>
								</div>
								<div class="imgbox">
									<div class="imgicon pointer" v-for="(imgitem,imgindex) in item.imagesList" :key="imgindex">
										<img :src="imgitem" class="img100" @click="checkimglist(imgindex,item.imagesList)">
									</div>
								</div>
							</div>
						</div>
						<div class="nolist fontsize16" v-if="dataList.length<=0">暂无相关评论记录，快来评论一下吧！</div>
						<!-- <div class="fenyebox" v-if="dataList.length>0">
							 <el-pagination
							    @current-change="handleCurrentChange"
							    :current-page.sync="currentPage"
							    :page-size="pageSize"
							    layout="prev, pager, next, jumper"
							    :total="total">
							</el-pagination>
						</div> -->
						<div style="margin-top: 20px;text-align: center;width: 100%;" v-if="!complete&&dataList.length>0"><el-button :loading="loadingshow" @click="getPagedata">点击加载更多</el-button></div>
						<div style="margin-top: 20px;text-align: center;width: 100%;" v-if="complete"><el-button>已经没有了~</el-button></div>
					</div>
				</div>
				<div class="rightbox">
					<div class="conbox">
						<div class="imgbox"><img :src="userInfo.hlImage || require('@/assets/logo.png')" class="img100"></div>
						<div class="username clamp fontsize20">{{userInfo.nikename}}</div>
						<div class="box1">
							<div class="item" @click="handleshowmyfabu(true)">
								<img src="../assets/static/Group427320837.png" class="icon">
								<div class="text fontsize16">我发布的话题</div>
							</div>
							<div class="item" @click="handleshowmyjoin(true)">
								<img src="../assets/static/Group427320838.png" class="icon">
								<div class="text fontsize16">我讨论的话题</div>
							</div>
						</div>
						<div class="btn1" @click="handleshowfabuhuati(true)">
							<img src="../assets/static/laba.png" class="icon">
							<div class="text fontsize16">发布话题</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- // 图片查看器 -->
		<el-image-viewer
		  v-if="showViewer"
		  :initial-index="imagesindex"
		  :on-close="closeViewer"
		  :zIndex="9999"
		  :url-list="checkimagesList" />
		<div class="block58"></div>
		<!-- 注册弹窗 -->
		<discussForm :isShow="showfabuhuati" :groupId="groupId" @handleForm="handlediscussForm" titlename="发布话题"></discussForm>
		<discussForm :isShow="showfabuhuatiedit" :dataobj="dataobj" :istype="'edit'" :groupId="groupId" @handleForm="handlediscussedit" titlename="编辑话题"></discussForm>
		<discussList ref="myset" :isShow="showmyfabu" :groupId="groupId" :istype="'myset'" @handleedit="handlefabuedit" @handleForm="handlediscussForm" titlename="我发布的话题"></discussList>
		<discussList ref="mysay" :isShow="showmyjoin" :groupId="groupId" :istype="'mysay'" @handledisdetail="handledisdetail" @handleForm="handlediscussForm" titlename="我讨论的话题"></discussList>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import {showLoading,hideLoading} from '@/common/loading.js';
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import draggable from 'vuedraggable'
import  discussForm  from "../components/registerForm/discussForm.vue";
import  discussList  from "../components/registerForm/discussList.vue";
export default {
	components: {
		ElImageViewer,
		draggable,
		discussForm,
		discussList
	},
	data() {
		return {
			uid:0,//话题id
			groupId:0,//讨论组id
			formLabelWidth:"128px",
			formObj: {},
			bannerList:[],
			imagesList:[],//评论图片
			showViewer:false,
			checkimagesList:[],
			imagesindex:0,
			dataList:[],
			currentPage:1,
			pageSize:10,
			complete:false,
			total:0,//总数
			loadingshow:false,
			ctCommentInfo:{
				comment:""
			},//评论
			showfabuhuati:false,//发布话题
			showfabuhuatiedit:false,//编辑话题
			dataobj:{},//编辑的话题详情
			showmyfabu:false,//我发布话题
			showmyjoin:false,//我参与话题
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$alert('参数ID丢失，请返回重试！', '提示', {
			    confirmButtonText: '好的',
			    callback: action => {
					this.sysgoback()
			    }
			});
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		...mapMutations(["SET_USERINFO"]),
		//置顶
		handleTopconmem(item,scindex){
			var _this = this
			var params = {} 
			params["id"] = item.id
			params["userUuid"] = this.userInfo.uuid
			params["topFlag"] = item.topFlag==1?0:1
			showLoading()
			this.$http.post('app/content/ctCommentInfo/updateTop', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					_this.$alert('顶置评论成功！', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							//刷新
							// if (scindex >= 0 && scindex < this.dataList.length) {
							//     const item = _this.dataList.splice(scindex, 1)[0];
							//     _this.dataList.unshift(item);
							// }
							//刷新
							_this.currentPage = 1
							_this.complete = false
							_this.dataList = []
							_this.getPagedata()
							
						}
					});
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//删除评论
		handledelconmem(item,scindex){
			var _this = this
			var params = {} 
			params["id"] = item.id
			params["userUuid"] = this.userInfo.uuid
			params["groupId"] = this.groupId
			showLoading()
			this.$http.post('app/content/ctCommentInfo/del', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					_this.$alert('删除话题评论成功！', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							_this.dataList.splice(scindex, 1)
							
						}
					});
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//我讨论话题跳转详情
		handledisdetail(item){
			this.$router.push({ path: '/discussDetail', query: {uid: item.objId }})
			this.$router.go(0);
		},
		//编辑我的文章
		handlefabuedit(item){
			this.dataobj = item
			this.showfabuhuatiedit = true
		},
		//关闭编辑的
		handlediscussedit(){
			this.showfabuhuatiedit = false
		},
		//我的发布弹窗
		handleshowmyfabu(type){
			var _this = this
			this.showmyfabu = type
			if(type){
				setTimeout(function(){
					_this.$refs.myset.refreshdata()
				},500)
			}
		},
		//我讨论的话题
		handleshowmyjoin(type){
			var _this = this
			this.showmyjoin = type
			if(type){
				setTimeout(function(){
					_this.$refs.mysay.refreshdata()
				},500)
			}
		},
		//发布话题成功
		handlediscussForm(type){
			this.showfabuhuati = false
			this.showfabuhuatiedit = false
			this.showmyfabu = false
			this.showmyjoin = false
		},
		//发布弹窗
		handleshowfabuhuati(type){
			this.showfabuhuati = type
		},
		//发布评论
		handlecomment(row){
			var _this = this
			if(!this.ctCommentInfo.comment){
				this.$message.error("请输入评论内容！")
				return;
			}
			var params = this.ctCommentInfo
			params["userUuid"] = this.userInfo.uuid
			params["groupId"] = this.formObj.groupId
			params["objId"] = this.uid
			params["imagesList"] = this.imagesList
			showLoading()
			this.$http.post('app/content/ctCommentInfo/save', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					// _this.$alert('发表评论成功，请等待审核！', '温馨提示', {
					// 	confirmButtonText: '确定',
					// 	callback: action => {
							
					// 	}
					// });
					_this.$message.success('发表评论成功')
					_this.ctCommentInfo.comment = ""
					_this.imagesList = []
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//下一页
		handleCurrentChange(currentPage){
			this.currentPage = currentPage
			this.getPagedata()
		},
		//目录
		getPagedata() {
			var _this = this
			if(_this.complete){
				return false;
			}
			this.loadingshow = true
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["objId"] = this.formObj.id
			//params["groupId"] = this.formObj.groupId
			this.$http.post('app/content/ctCommentInfo/index', params).then(function(res) {
				_this.loadingshow = false
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.imagesList&&item.imagesList.length>0){
								item["imgUrl"] = item.imagesList[0]
							}else{
								item.imagesList = []
							}
						})
						_this.dataList = _this.dataList.concat(records)
						_this.currentPage +=1
					}else{
						_this.complete = true
					}
					//_this.dataList = records
					//_this.total = res.data.total;
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取详情
		getDataDetail() {
			var _this = this
			var params = {
				id:this.uid
			}
			this.$http.post("app/content/ctDiscussInfo/toDetailNoList", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.formObj = res.data
					_this.groupId = _this.formObj.groupId
					if( res.data.imgUrlList){
						_this.bannerList = JSON.parse( res.data.imgUrlList)
					}
					//目录
					_this.getPagedata()
				}else{
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//多图查看
		checkimglist(index,imagesList){
			//当前之前/当前之后
			let startArrImgs = imagesList.slice(index,imagesList.length)
			let endArrImgs = imagesList.slice(0,index)
			startArrImgs.push.apply(startArrImgs, endArrImgs)
			//【1，2，3】【4，5】---【1，2，3，4，5】
			// this.imagesindex = 0
			//console.log(startArrImgs)
			this.checkimagesList = startArrImgs
			this.showViewer = true
		},
		//关闭
		closeViewer(){
			this.imagesindex = 0
			this.checkimagesList = []
			this.showViewer = false
		},
		dragChange(en) {
			console.log(en)
		},
		// 删除多图轮播
		removeimglist(imgindex) {
			this.imagesList.splice(imgindex, 1)
		},
		async handlelistSuccess({
			file
		}) {
			var res = await this.$api.uploadHttp(file, {})
			if (res && res.status == 200) {
				//this.$emit('addimglist', res.requestUrls[0])
				this.imagesList.push(res.requestUrls[0])
				this.$message.success('上传图片成功!')
			} else {
				this.$message.error('上传图失败!')
			}
		},
		beforelistUpload(file) {
			console.log(file)
			const isLt2M = file.size / 1024 / 1024 < 10
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!')
				return false
			}
			if (file.type == 'image/jpeg' || file.type == 'image/png') {
				
			} else {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
				return false
			}
		}
		
	}
};
</script>
<style lang="scss" scoped>
	.fenyebox{
		height: 88px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.discuss_typebox{
		width: 100%;
		background-color: #f8f8f8;
		.titlename{
			display: flex;
			// align-items: center;
			color: #222;
			cursor: pointer;
			.icon{
				width: 28px;
				height: 28px;
				margin-right: 8px;
				margin-top:4px;
			}
			.text{
				padding-bottom: 8px;
				border-bottom: 1px solid #222;
			}
		}
	}
	.discuss_detailbox{
		width: 100%;
		padding: 28px 0;
		.leftbox{
			// width: 836px;
			flex: 1;
			.topbox{
				width: 100%;
				.titlebox{
					width: 100%;
					color: #000;
				}
				.descbox{
					width: 100%;
					margin-top: 10px;
					display: flex;
					align-items: center;
					.userinfo{
						flex-shrink: 0;
						display: flex;
						align-items: center;
						.logoimg{
							width: 28px;
							height: 28px;
							border-radius: 28px;
							background-color: #f8f8f8;
							flex-shrink: 0;
						}
						.text{
							flex: 1;
							margin-left: 8px;
							color: #666;
						}
					}
					.timer{
						margin-left: 28px;
						flex-shrink: 0;
						color: #666;
					}
				}
			}
			.centerbox{
				width: 100%;
				padding: 18px 0;
				border-bottom: 1px solid #E6E6E6;
				.bannerbox{
					width: 100%;
					overflow: hidden;
					// background: #3167F6;
					.el-carousel__container{
						height: 368px;
					}
				}
				.contextbox{
					width: 100%;
					margin-top: 18px;
					color: #666;
					line-height: 180%; /* 25.2px */
					.text{
						width: 100%;
						white-space: pre-line;
					}
				}
			}
			.bottombox{
				width: 100%;
				.pinglunbox{
					width: 100%;
					.titletext{
						width: 100%;
						height: 58px;
						display: flex;
						align-items: center;
						color: #000;
					}
					.inputbox{
						width: 100%;
						padding: 12px;
						box-sizing: border-box;
						border-radius: 8px;
						background: #F7F8FB;
						.textarea{
							width: 100%;
							height: 58px;
							border: none;
							background: #F7F8FB;
						}
						.btnbox{
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							.imgicon{
								width: 22px;
								height: 22px;
								cursor: pointer;
							}
							.btn1{
								width: 60px;
								height: 36px;
								line-height: 36px;
								text-align: center;
								border-radius: 8px;
								background: #3191FF;
								color: #FFF;
								margin: 0 10px;
								flex-shrink: 0;
								cursor: pointer;
							}
						}
					}
				}
			}
			.pinglun_box{
				width: 100%;
				.itemobj{
					width: 100%;
					padding: 20px 0;
					border-bottom: 1px solid #E6E6E6;
					.topbox{
						width: 100%;
						display: flex;
						align-items: center;
						.descbox{
							flex: 1;
							margin-top: 10px;
							display: flex;
							align-items: center;
							.userinfo{
								flex-shrink: 0;
								display: flex;
								align-items: center;
								.logoimg{
									width: 28px;
									height: 28px;
									border-radius: 28px;
									background-color: #f8f8f8;
									flex-shrink: 0;
								}
								.text{
									flex: 1;
									margin-left: 8px;
									color: #666;
								}
							}
							.timer{
								margin-left: 28px;
								flex-shrink: 0;
								color: #666;
							}
						}
						.editbox{
							margin-left: auto;
							flex-shrink: 0;
							display: flex;
							align-items: center;
							.zdimg{
								width: 25px;
								height: 25px;
								margin-right: 20px;
								cursor: pointer;
							}
							.scimg{
								width: 25px;
								height: 25px;
								cursor: pointer;
							}
						}
					}
					.conbox{
						width: 100%;
						margin-top: 8px;
						padding-left: 35px;
						box-sizing: border-box;
						.context{
							color: #000;
							.text{
								white-space: pre-line;
							}
						}
						.imgbox{
							width: 100%;
							display: flex;
							flex-wrap: wrap;
							.imgicon{
								width: 88px;
								height: 88px;
								margin-top: 10px;
								margin-right: 10px;
								border-radius: 5px;
								overflow: hidden;
							}
						}
					}
				}
			}
		}
		.rightbox{
			width: 324px;
			margin-left: 38px;
			flex-shrink: 0;
			margin-top: 38px;
			.conbox{
				padding: 28px;
				display: flex;
				flex-direction: column;
				align-items: center;
				background: #F7F8FB;
				border-radius: 24px;
				.imgbox{
					width: 100px;
					height: 100px;
					flex-shrink: 0;
					border-radius: 100px;
					overflow: hidden;
					margin-top: -58px;
				}
				.username{
					width: 100%;
					color: #000;
					margin-top: 20px;
					text-align: center;
				}
				.box1{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 16px;
					.item{
						width: 50%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						.icon{
							width: 68px;
							height: 68px;
							flex-shrink: 0;
						}
						.text{
							color: #000;
							margin-top: 15px;
						}
					}
				}
				.btn1{
					width: 168px;
					height: 44px;
					margin-top: 38px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 22px;
					background: #222;
					cursor: pointer;
					.icon{
						width: 24px;
						height: 24px;
						flex-shrink: 0;
					}
					.text{
						color: #FFF;
						margin-left: 8px;
					}
				}
			}
		}
	}
	.content_imgbox {
		width: 100%;
		margin: 10px 0;
	}
	.image-list {
		display: flex;
		flex-wrap: wrap;
	}
	.image-list .image-wrap {
		position: relative;
		display: inline-block;
		box-sizing: content-box;
		margin-right: 10px;
		line-height: 0;
		vertical-align: top;
		border-radius: 6px;
		overflow: hidden;
	}
	
	.image-list .imgStyle {
		width: 68px;
		height: 68px;
		border: 1px solid #d9d9d9;
	}
	
	.avatar-uploaderde {
		width: 68px;
		height: 68px;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
	}
	
	.avatar-uploader-iconde {
		font-size: 28px;
		color: #8c939d;
		width: 68px;
		height: 68px;
		line-height: 68px !important;
		text-align: center;
	}
	
	.image-list .icon-wrap {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 30px;
		cursor: default;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		opacity: 0;
		font-size: 20px;
		background-color: rgba(0, 0, 0, 0.7);
		transition: opacity 0.3s;
		z-index: 8888;
	}
	
	.image-list .image-wrap:hover {
		.icon-wrap {
			opacity: 1;
		}
	}
	
	.image-list .el-icon-zoom-in {
		cursor: pointer;
		margin-right: 8px;
	}
	
	.image-list .el-icon-delete {
		cursor: pointer;
	}
</style>
